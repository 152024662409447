import { createSlice } from "@reduxjs/toolkit"

const initialState = {}

const formQuestionsSlice = createSlice({
  name: "formQuestions",
  initialState,
  reducers: {
    addField(state, action) {
      state[action.payload.formFieldId] = {
        fieldData: action.payload,
        weight: null,
        options: {},
      }
    },
    removeField(state, action) {
      delete state[action.payload.formFieldId]
    },
    clearFields(state, action) {
      state = initialState
    },
    setWeight(state, action) {
      state[action.payload.fieldId].weight = action.payload.weight
    },
    setSubweight(state, action) {
      state[action.payload.fieldId].options[action.payload.optionId] =
        action.payload.optionWeight
    },
    setScoringPrompt(state, action) {
      state[action.payload.fieldId].scoringPrompt = action.payload.value
    },
    clearWeights(state, action) {
      Object.keys(state).forEach((fieldId) => {
        state[fieldId] = {}
      })
    },
  },
})

export const {
  addField,
  removeField,
  clearFields,
  setWeight,
  setSubweight,
  setScoringPrompt,
  clearWeights,
} = formQuestionsSlice.actions

export const selectFormQuestions = (state) => state.formQuestions
export const selectFormQuestionsCount = (state) =>
  Object.keys(state.formQuestions).length
export const selectFormQuestion = (fieldId) => (state) =>
  state.formQuestions[fieldId]
export const selectFormQuestionsTotalWeight = (state) =>
  Object.values(state.formQuestions).reduce(
    (total, currentValue) => (total += currentValue.weight),
    0,
  )
export const selectFormQuestionOptionWeightTotal = (fieldId) => (state) =>
  Object.values(state.formQuestions[fieldId].options).reduce(
    (total, currentValue) =>
      (total =
        Number(total.toFixed(String(currentValue).split(".")[1]?.length ?? 0)) +
        Number(
          currentValue.toFixed(String(currentValue).split(".")[1]?.length ?? 0),
        )),
    0,
  )
export const selectAreFormQuestionOptionWeightsValid = (state) =>
  Object.values(state.formQuestions)
    .filter((formQuestion) =>
      ["dropdown_list", "single_response"].includes(
        formQuestion.fieldData.fieldType,
      ),
    )
    .every(
      (formQuestion) =>
        Object.values(formQuestion?.options).reduce(
          (total, currentValue) =>
            (total =
              Number(
                total.toFixed(String(currentValue).split(".")[1]?.length ?? 0),
              ) +
              Number(
                currentValue.toFixed(
                  String(currentValue).split(".")[1]?.length ?? 0,
                ),
              )),
          0,
        ) === 100,
    )

export default formQuestionsSlice.reducer
