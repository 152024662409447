import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  orgApiKey: import.meta.env.VITE_ORG_API_KEY,
  projectId: import.meta.env.VITE_DEMO_PROJECT_ID,
  initialFormId: null,
  missionStatement:
    "Our mission is to empower individuals and communities by promoting equitable access to quality STEM education and opportunities. We strive to inspire and equip learners of all ages, with a particular focus on underserved populations, to develop critical thinking, problem-solving, and innovation skills. Through collaboration, advocacy, and the provision of resources, we aim to bridge the gap in STEM education, foster a diverse and inclusive STEM workforce, and cultivate the next generation of leaders and innovators in science, technology, engineering, and mathematics. Together, we aspire to create a future where every individual has the tools and support necessary to thrive in the rapidly evolving world of STEM.",
  numberToRank: 30,
  organization: {},
}

const configurationSlice = createSlice({
  name: "configuration",
  initialState,
  reducers: {
    addProjectId(state, action) {
      state.projectId = action.payload
    },
    addInitialFormId(state, action) {
      state.initialFormId = action.payload
    },
    addMissionStatement(state, action) {
      state.missionStatement = action.payload
    },
    addNumberToRank(state, action) {
      state.numberToRank = action.payload
    },
    addOrganizationInfo(state, action) {
      state.organization = action.payload
    },
  },
})

export const selectConfiguration = (state) => state.configuration
export const selectConfigurationIsComplete = (state) =>
  Boolean(
    state.configuration.orgApiKey,
    state.configuration.projectId,
    state.configuration.initialFormId,
    state.configuration.missionStatement,
  )

export const {
  addProjectId,
  addInitialFormId,
  addMissionStatement,
  addOrganizationInfo,
  addNumberToRank,
} = configurationSlice.actions
export default configurationSlice.reducer
