import PropTypes from "prop-types"
import { InputNumber } from "rsuite"
import { useState, useEffect } from "react"

const FormQuestionSetSubWeight = ({ onChange, weight }) => {
  const [localWeight, setLocalWeight] = useState(weight || 0)

  useEffect(() => {
    setLocalWeight(weight || 0)
  }, [weight])

  const handleChange = (value) => {
    setLocalWeight(value)
    onChange(value)
  }

  return (
    <div>
      <InputNumber
        size="xs"
        style={{ width: 110 }}
        postfix="%"
        scrollable={false}
        min={0}
        max={100}
        value={localWeight}
        onChange={handleChange}
      />
    </div>
  )
}

FormQuestionSetSubWeight.propTypes = {
  weight: PropTypes.number,
  onChange: PropTypes.func,
}

export default FormQuestionSetSubWeight
