import PropTypes from "prop-types"
import { Toggle, Panel } from "rsuite"
import style from "./index.module.css"
import { SUPPORTED_FIELD_TYPES } from "../../utils/constants"

const FormQuestionSelector = ({ fieldData, isSelected, onSelect }) => {
  return (
    <div className={style.fieldRoot}>
      <span className={style.labelAndType}>
        <h6 className={style.label}>{fieldData.label}</h6>
        <p>Question type: {SUPPORTED_FIELD_TYPES[fieldData.fieldType]}</p>
      </span>
      <Toggle checked={isSelected} onChange={onSelect} />
    </div>
  )
}

FormQuestionSelector.propTypes = {
  fieldData: PropTypes.shape({
    formFieldId: PropTypes.string,
    label: PropTypes.string,
    fieldType: PropTypes.string,
  }),
  isSelected: PropTypes.bool,
  onSelect: PropTypes.func,
}

export default FormQuestionSelector
