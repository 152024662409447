import FormQuestionSetWeight from "./FormQuestionSetWeight"
import style from "./index.module.css"
import { useSelector, useDispatch } from "react-redux"
import {
  selectFormQuestions,
  selectFormQuestionsTotalWeight,
  setWeight,
} from "../selectQuestions/formQuestionsSlice"
import { useEffect } from "react"
import { Panel, Affix, Message } from "rsuite"
import { Doughnut } from "react-chartjs-2"

const AssignQuestionWeights = ({ assignPreWeights = true }) => {
  const dispatch = useDispatch()
  const formQuestions = useSelector(selectFormQuestions)
  const totalWeights = useSelector(selectFormQuestionsTotalWeight)

  const handleSetQuestionWeight = (value, fieldId) => {
    dispatch(setWeight({ fieldId, weight: Number(value) }))
  }

  useEffect(() => {
    const preWeights = [
      { "What is your gender?": 10 },
      { "What is your ethnicity/race?": 10 },
      { "What is your annual household income?": 10 },
      {
        "Please describe your organization, including your mission and activities": 35,
      },
      { "What is the problem to be addressed by this grant award?": 35 },
    ]

    let formQuestionsData = Object.values(formQuestions)

    if (formQuestionsData.length) {
      formQuestionsData.map((data) => {
        let field = preWeights.find((field) => field[data.fieldData.label])
        let weight = field ? Object.values(field)[0] : 0
        let fieldId = data.fieldData.formFieldId

        // Pre-set weight if not already set
        if (data.weight === null) {
          return dispatch(setWeight({ fieldId, weight: weight }))
        }
      })
    }
  }, [])

  return (
    <div
      style={{
        display: "grid",
        gap: "16px",
        gridAutoFlow: "column",
        gridTemplateColumns: "65% 35%",
      }}
    >
      <Panel className={style.weightPanel} bordered>
        <h3>Assign Weights</h3>
        <p>
          For each question you've selected, please add a weight according to
          the importance of the answer for the submission's overall eligibility
          to receive the grant. Weight values should range from 0-100, and sum
          up to 100 total across all questions.
        </p>

        {Object.values(formQuestions)?.map((data) => (
          <FormQuestionSetWeight
            key={data.fieldData.formFieldId}
            fieldData={data.fieldData}
            weight={data.weight}
            onChange={(value) =>
              handleSetQuestionWeight(value, data.fieldData.formFieldId)
            }
            assignPreWeights={assignPreWeights}
          />
        ))}
      </Panel>

      <Affix top={10}>
        <Panel bordered style={{ height: "100%" }}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <h3>Summary</h3>
            <Message
              style={{
                border: totalWeights === 100 ? "1px solid green" : "none",
              }}
              showIcon={totalWeights === 100}
              type={totalWeights === 100 ? "success" : "error"}
            >
              {totalWeights}% Weighted
            </Message>
          </div>

          <div className={style.summaryContainer}>
            <Doughnut
              options={{
                radius: 150,
                plugins: {
                  legend: {
                    position: "bottom",
                    align: "start",
                  },
                },
              }}
              data={{
                labels: Object.values(formQuestions)?.map(
                  (data) => data.fieldData.label,
                ),
                datasets: [
                  {
                    backgroundColor: [
                      "rgba(255, 99, 132, 0.2)",
                      "rgba(54, 162, 235, 0.2)",
                      "rgba(255, 206, 86, 0.2)",
                      "rgba(75, 192, 192, 0.2)",
                      "rgba(153, 102, 255, 0.2)",
                      "rgba(255, 159, 64, 0.2)",
                    ],
                    borderColor: [
                      "rgba(255, 99, 132, 1)",
                      "rgba(54, 162, 235, 1)",
                      "rgba(255, 206, 86, 1)",
                      "rgba(75, 192, 192, 1)",
                      "rgba(153, 102, 255, 1)",
                      "rgba(255, 159, 64, 1)",
                    ],
                    borderWidth: 1,
                    data: Object.values(formQuestions)?.map(
                      (data) => data.weight,
                    ),
                  },
                ],
              }}
            />
          </div>
        </Panel>
      </Affix>
    </div>
  )
}

export default AssignQuestionWeights
