// @ts-nocheck
import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit"
import { servicesApi } from "./apis/servicesV3"
import { crystalBallApi } from "./apis/crystalBall"
import formQuestionsReducer from "../features/selectQuestions/formQuestionsSlice"
import configurationReducer from "../features/reportConfiguration/configurationSlice.js"

export const store = configureStore({
  reducer: {
    formQuestions: formQuestionsReducer,
    configuration: configurationReducer,
    [crystalBallApi.reducerPath]: crystalBallApi.reducer,
    [servicesApi.reducerPath]: servicesApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(servicesApi.middleware)
      .concat(crystalBallApi.middleware),
})

export const getStore = (state) => state

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>
