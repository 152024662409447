import { useState } from "react"
import { useSelector } from "react-redux"
import { Button } from "rsuite"
import {
  selectAreFormQuestionOptionWeightsValid,
  selectFormQuestions,
  selectFormQuestionsCount,
  selectFormQuestionsTotalWeight,
} from "./features/selectQuestions/formQuestionsSlice"
import { selectConfiguration } from "./features/reportConfiguration/configurationSlice"
import Configuration from "./features/reportConfiguration/Configuration"
import AssignQuestionWeights from "./features/assignQuestionWeights/AssignQuestionWeights"
import PredictionReport from "./features/predictionReport/PredictionReport"
import "./App.css"
import "rsuite/dist/rsuite.min.css"
import {
  useLazyGetSubmissionRankingsQuery,
  useScoreSubmissionsMutation,
} from "./app/apis/crystalBall"
import PostAwardAnalysis from "./features/postAward/PostAwardAnalysis"
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Filler,
} from "chart.js"
import annotationPlugin from "chartjs-plugin-annotation"

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Filler,
  annotationPlugin,
)

ChartJS.overrides["pie"].plugins.legend.labels.boxWidth = 20
ChartJS.overrides["pie"].plugins.legend.position = "bottom"
ChartJS.overrides["pie"].plugins.legend.align = "start"
ChartJS.overrides["pie"].plugins.legend.maxWidth = 1000
ChartJS.overrides["doughnut"].plugins.legend.labels.boxWidth = 20
ChartJS.overrides["doughnut"].plugins.legend.position = "bottom"
ChartJS.overrides["doughnut"].plugins.legend.align = "start"

const steps = [
  Configuration,
  AssignQuestionWeights,
  PredictionReport,
  PostAwardAnalysis,
]

function App() {
  const selectedQuestionsCount = useSelector(selectFormQuestionsCount)
  const totalWeights = useSelector(selectFormQuestionsTotalWeight)
  const config = useSelector(selectConfiguration)
  const areOptionWeightsValid = useSelector(
    selectAreFormQuestionOptionWeightsValid,
  )
  const formFields = useSelector(selectFormQuestions)

  const [isLoadingRankings, setIsLoadingRankings] = useState(false)

  const [scoreSubmissions] = useScoreSubmissionsMutation()
  const [getRankings, { data: rankings }] = useLazyGetSubmissionRankingsQuery()
  const [previousRankings, setPreviousRankings] = useState([])
  const [step, setStep] = useState(0)
  const CurrentStep = steps[step]
  const stepValidations = [
    () => selectedQuestionsCount > 0 && selectedQuestionsCount <= 10,
    () => totalWeights === 100,
    () => !!rankings,
    () => null,
  ]

  const getScoringPrompt = (field) => {
    return ["short_answer", "long_answer"].includes(field.fieldData.fieldType)
      ? field.scoringPrompt || config.missionStatement
      : undefined
  }

  const getOptions = (field) => {
    return ["short_answer", "long_answer"].includes(field.fieldData.fieldType)
      ? undefined
      : Object.entries(field.options).map(([key, value]) => ({
          optionId: key,
          weight: value,
        }))
  }

  const handleSubmit = () => {
    if (rankings) {
      setPreviousRankings(rankings)
    }

    let payload = {
      projectId: config.projectId,
      formId: config.initialFormId,
      missionStatement: config.missionStatement,
      fieldWeights: Object.values(formFields).map((field) => ({
        fieldId: field.fieldData.formFieldId,
        fieldType: field.fieldData.fieldType,
        weight: field.weight,
        scoringPrompt: getScoringPrompt(field),
        options: getOptions(field),
      })),
    }

    setIsLoadingRankings(true)
    // Call API to Submit
    scoreSubmissions({ payload: payload, orgApiKey: config.orgApiKey })
      .unwrap()
      .then(() => {
        getRankings({
          orgApiKey: config.orgApiKey,
          projectId: config.projectId,
          formId: config.initialFormId,
          pageSize: config.numberToRank,
        })
          .unwrap()
          .then(() => setIsLoadingRankings(false))
      })
  }
  const handleNextClick = () => {
    if (stepValidations[step]()) {
      if (step === 1) {
        handleSubmit()
      }

      setStep((step) => step + 1)
    } else {
      alert("failed validation")
    }
  }
  const handleBackClick = () => {
    if (step > 0) {
      setStep((step) => (step -= 1))
    }
  }

  return (
    <div className="App">
      <div
        style={{
          textAlign: "center",
          display: "flex",
          gap: 16,
          alignItems: "center",
        }}
      >
        <img alt="Submittable Logo" src="./assets/submittableLogo.png" />
        <h1>Project Crystal Ball</h1>
      </div>

      <p style={{ fontSize: "1rem" }}>
        An AI powered assistant that helps you find and award the right people.
      </p>

      <div style={{ width: "100%" }}>
        <CurrentStep
          orgApiKey={config.orgApiKey}
          rankings={rankings}
          previousRankings={previousRankings}
          isDrawer={false}
          handleSubmit={handleSubmit}
          isLoading={isLoadingRankings}
        />
      </div>

      {isLoadingRankings ? null : (
        <div className="buttons">
          <Button size="lg" appearance="ghost" onClick={handleBackClick}>
            Back
          </Button>
          <Button
            disabled={!stepValidations[step]()}
            size="lg"
            appearance="primary"
            onClick={handleNextClick}
          >
            {step === 1 ? "Submit" : "Next"}
          </Button>
        </div>
      )}
    </div>
  )
}

export default App
