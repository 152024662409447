import styles from "./Ranking.module.css"
import { useSelector } from "react-redux"
import Grid from "rsuite/Grid"
import Row from "rsuite/Row"
import Col from "rsuite/Col"
import { selectConfiguration } from "../reportConfiguration/configurationSlice"
import { Button, Whisper, Tooltip } from "rsuite"
import FlipMove from "react-flip-move"
import { useState, useEffect } from "react"

const COLUMNS = [1, 1, 2, 11, 4, 2, 3]
const SubmissionList = ({
  rankings,
  previousRankings,
  isLoading,
  submissionCount,
}) => {
  const config = useSelector(selectConfiguration)

  const [lastRankings, setLastRankings] = useState(
    previousRankings?.rankedSubmissions || [],
  )
  const [rankedSubmissions, setRankedSubmissions] = useState(
    rankings?.rankedSubmissions || [],
  )

  useEffect(() => {
    setRankedSubmissions(rankings?.rankedSubmissions)
  }, [rankings])

  useEffect(() => {
    if (!isLoading) {
      setLastRankings(previousRankings?.rankedSubmissions)
    }
  }, [previousRankings, isLoading])

  const getIndexDiff = (ranking) => {
    // Do we have a previous result to compare with?
    if (lastRankings?.length) {
      let prevIndex = lastRankings.findIndex(
        (s) => s.submissionId === ranking.submissionId,
      )

      let currentIndex = rankedSubmissions?.findIndex(
        (s) => s.submissionId === ranking.submissionId,
      )

      if (prevIndex === -1) {
        return "New"
      }

      let diff = prevIndex - currentIndex

      if (diff > 0) {
        return `+${diff}`
      } else {
        return diff
      }
    }

    // No? Fine, this was our first time scoring anyway
    return "New"
  }

  const getScoreDiff = (ranking) => {
    // Do we have a previous result to compare with?
    if (lastRankings?.length) {
      let prevScore = lastRankings.find(
        (x) => x.submissionId === ranking.submissionId,
      )?.score
      let currentScore = ranking?.score

      let diff = Math.round((currentScore - prevScore) * 100) / 100

      if (diff > 0) {
        return `(+${diff}%)`
      } else if (diff < 0) {
        return `(${diff}%)`
      } else {
        return ""
      }
    }

    // No? Fine, this was our first time scoring anyway
    return ""
  }

  const getButtonStyle = (ranking) => {
    let style = {
      width: "50px",
      backgroundColor: "#f5f5f5",
      color: "black",
    }
    let diff = getIndexDiff(ranking)

    if (diff > 0) {
      style.backgroundColor = "green"
      style.color = "white"
    } else if (diff < 0) {
      style.backgroundColor = "red"
      style.color = "white"
    }

    return style
  }

  const getRowStyle = (ranking) => {
    let style = {}
    let diff = getIndexDiff(ranking)

    if (diff > 0) {
      style.backgroundColor = "#f2fcf6"
    } else if (diff < 0) {
      style.backgroundColor = "#fcf2f2"
    } else {
      style.backgroundColor = "white"
    }

    return style
  }

  return (
    <div>
      <Grid fluid className={styles.submissionsPanel}>
        {/* TEMP: only show top 10 */}
        <FlipMove
          duration={500}
          enterAnimation="accordionVertical"
          leaveAnimation="accordionVertical"
        >
          {rankedSubmissions?.slice(0, submissionCount)?.map((ranking) => (
            <Row
              style={getRowStyle(ranking)}
              className={styles.gridRow}
              key={ranking.submissionId}
            >
              <Col className={styles.gridCol} xs={COLUMNS[0]} />
              <Col className={styles.gridCol} xs={COLUMNS[1]}>
                <b>{rankedSubmissions.indexOf(ranking) + 1}</b>
              </Col>
              <Col className={styles.gridCol} xs={COLUMNS[2]}>
                <Whisper
                  placement="top"
                  controlId="control-id-hover"
                  trigger="hover"
                  speaker={
                    <Tooltip>
                      {getIndexDiff(ranking) === "New"
                        ? `This is a new ranking`
                        : `The rank of this submission changed by ${getIndexDiff(
                            ranking,
                          )} after the latest updates`}
                    </Tooltip>
                  }
                >
                  <Button appearance="primary" style={getButtonStyle(ranking)}>
                    {getIndexDiff(ranking)}
                  </Button>
                </Whisper>
              </Col>
              <Col className={styles.gridCol} xs={COLUMNS[3]}>
                <a
                  href={`https://${config?.organization?.domain}/submissions/${ranking?.submissionId}`}
                  target="_blank"
                >
                  {ranking.submissionTitle}
                </a>
              </Col>
              <Col className={styles.gridCol} xs={COLUMNS[4]}>
                <b>{ranking.submitterName}</b>
              </Col>
              <Col className={styles.gridCol} xs={COLUMNS[5]}>
                {`${Math.round(ranking.score * 100) / 100}% ${getScoreDiff(
                  ranking,
                )}`}
              </Col>
            </Row>
          ))}
        </FlipMove>
      </Grid>
    </div>
  )
}

export default SubmissionList
