import PropTypes from "prop-types"
import { InputNumber, Input, Panel, Stack, Col, Row, Loader } from "rsuite"
import style from "./index.module.css"
import {
  selectFormQuestionOptionWeightTotal,
  selectFormQuestion,
  setSubweight,
  setScoringPrompt,
} from "../selectQuestions/formQuestionsSlice"
import { SUPPORTED_FIELD_TYPES } from "../../utils/constants"
import { useSelector, useDispatch } from "react-redux"
import FormQuestionSetSubWeight from "./FormQuestionSetSubWeight"
import { useEffect } from "react"
import { useGetSubmissionDistributionQuery } from "../../app/apis/crystalBall"
import { selectConfiguration } from "../reportConfiguration/configurationSlice"

const FormQuestionSetWeight = ({
  fieldData,
  weight,
  onChange,
  assignPreWeights,
}) => {
  const dispatch = useDispatch()
  const config = useSelector(selectConfiguration)
  const subWeightTotals = useSelector(
    selectFormQuestionOptionWeightTotal(fieldData.formFieldId),
  )
  const formQuestion = useSelector(selectFormQuestion(fieldData.formFieldId))

  const handleSubWeightChange = (value, option) => {
    dispatch(
      setSubweight({
        fieldId: fieldData.formFieldId,
        optionId: option.formOptionId,
        optionWeight: Number(value),
      }),
    )
  }

  const { data, isLoading } = useGetSubmissionDistributionQuery({
    orgApiKey: config.orgApiKey,
    projectId: config?.projectId,
  })

  // Set some initial sub field weights
  useEffect(() => {
    let fieldOptions = fieldData.options || []

    if (fieldOptions.length && !!data) {
      fieldOptions.map((option) => {
        let fieldId = fieldData.formFieldId
        let optionId = option.formOptionId

        if (assignPreWeights) {
          return dispatch(
            setSubweight({ fieldId, optionId, optionWeight: 100 }),
          )
        }
        return null
      })
    }
  }, [data])

  // preset preferred response values
  useEffect(() => {
    if (
      fieldData?.label ===
        "Please describe your organization, including your mission and activities" &&
      !formQuestion.scoringPrompt
    ) {
      dispatch(
        setScoringPrompt({
          fieldId: fieldData?.formFieldId,
          value: config?.missionStatement,
        }),
      )
    }

    if (
      fieldData?.label ===
        "What is the problem to be addressed by this grant award?" &&
      !formQuestion.scoringPrompt
    ) {
      dispatch(
        setScoringPrompt({
          fieldId: fieldData?.formFieldId,
          value:
            "Despite the increasing demand for STEM (science, technology, engineering, and mathematics) skills in today's workforce, there exists a significant and persistent disparity in access to quality STEM education and opportunities, particularly among underserved populations. This inequality perpetuates a cycle of limited exposure, resources, and support, hindering the development of critical thinking, problem-solving, and innovation skills necessary for success in STEM fields. Lack of equitable access to STEM education disproportionately affects marginalized communities, including girls, students from low-income backgrounds, and underrepresented minorities. Limited resources, inadequate teacher training, and insufficient curriculum often result in these students being left behind, with reduced opportunities to explore and pursue STEM pathways. This not only perpetuates the existing disparities but also hampers the potential for diverse perspectives and contributions in the STEM workforce. Moreover, the rapid advancements in technology and the increasing importance of STEM in various industries necessitate a well-prepared and diverse talent pool. Without addressing these inequities and ensuring inclusive STEM education for all, we risk a future where underserved communities are further marginalized, and our society fails to harness the full potential of its human capital. To address this pressing challenge, we seek funding to support initiatives that promote equitable access to quality STEM education, prioritize underrepresented populations, and empower individuals and communities to excel in STEM fields. By investing in targeted programs, resources, and support, we can break the cycle of disparity, foster diversity and inclusion in STEM, and cultivate the next generation of leaders and innovators who will drive economic growth, tackle global challenges, and shape a better future for all.",
        }),
      )
    }
  }, [])

  const getSubmissionDistribution = (fieldData, option) => {
    return data?.allSubmissionDistributions
      ?.find((field) => field.fieldId === fieldData.formFieldId)
      ?.submissionDistributions?.find(
        (opt) => opt.optionId === option.formOptionId,
      )?.percentage
  }

  return (
    <div className={style.fieldRoot}>
      <Row>
        <Col md={19}>
          <span className={style.label}>{fieldData.label}</span>
        </Col>
        <Col style={{ float: "right" }}>
          <InputNumber
            style={{ width: 120 }}
            postfix="%"
            scrollable={false}
            min={0}
            max={100}
            value={weight}
            onChange={onChange}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <em>Question type: {SUPPORTED_FIELD_TYPES[fieldData.fieldType]}</em>
        </Col>
      </Row>
      <Row>
        <Col md={24}>
          {["short_answer", "long_answer"].includes(fieldData?.fieldType) && (
            <div className={style.preferredResponse}>
              <Input
                as="textarea"
                rows={5}
                placeholder="Preferred response"
                value={formQuestion.scoringPrompt}
                onChange={(value) =>
                  dispatch(
                    setScoringPrompt({ value, fieldId: fieldData.formFieldId }),
                  )
                }
              />
            </div>
          )}
        </Col>
        <Col xs={24}>
          {fieldData?.options?.length > 0 && (
            <Panel
              className={style.formFieldOptionsRoot}
              defaultExpanded={false}
              collapsible
              style={{
                backgroundColor: "#f5f5f5",
              }}
              header={
                <span>
                  {isLoading && assignPreWeights ? (
                    <Loader
                      size="sm"
                      content="Fetching demographic information for applicant pool"
                    />
                  ) : (
                    `Field Option Weights`
                  )}
                </span>
              }
            >
              <div>
                <em>
                  Form option weights are the percentage of the parent field
                  weight to contribute to overall score, and can total more than
                  100%.
                </em>
              </div>
              <br />
              {fieldData.options.map((option) => (
                <div style={{ marginBottom: 20 }} key={option.formOptionId}>
                  <Stack spacing={5}>
                    <b>{option.label}</b>

                    <FormQuestionSetSubWeight
                      onChange={(value) => {
                        handleSubWeightChange(value, option)
                      }}
                      weight={Number(formQuestion.options[option.formOptionId])}
                    />
                    <span>
                      <em>
                        Submission Population:{" "}
                        <b>
                          {getSubmissionDistribution(fieldData, option) || 0}%
                        </b>
                      </em>
                    </span>
                  </Stack>
                </div>
              ))}
            </Panel>
          )}
        </Col>
      </Row>
    </div>
  )
}

FormQuestionSetWeight.propTypes = {
  fieldData: PropTypes.shape({
    formFieldId: PropTypes.string,
    label: PropTypes.string,
    fieldType: PropTypes.string,
  }),
  weight: PropTypes.number,
  onChange: PropTypes.func,
}

export default FormQuestionSetWeight
