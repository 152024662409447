import React from "react"
import styles from "./Analysis.module.css"
import { SUPPORTED_FIELD_TYPES } from "../../utils/constants"
import { Pie } from "react-chartjs-2"

const QuestionAnalysis = ({ question, pieRadius, isPrinting = false }) => {
  const totalResponses =
    question?.optionCounts.reduce((sum, val) => sum + val.count, 0) || 0

  if (
    ["short_answer", "long_answer"].includes(question?.fieldData?.fieldType)
  ) {
    return null
  }

  const optionData = Object.values(question?.fieldData?.options)
  const getOptionPercentages = () =>
    optionData?.reduce((percentages, current) => {
      const responseCount = question?.optionCounts?.find(
        (option) => option.optionId === current.formOptionId,
      )

      const responsePercent = (responseCount?.count / totalResponses) * 100
      return [...percentages, responsePercent]
    }, [])
  const getOptionWeights = () =>
    optionData?.reduce((weights, current) => {
      const currentWeight = question.options[current.formOptionId]
      return [...weights, currentWeight]
    }, [])

  return (
    <div>
      <h4>{question.fieldData.label}</h4>
      <div className={styles.sublabel}>
        {SUPPORTED_FIELD_TYPES[question.fieldData.fieldType]} question
      </div>

      <div style={{ maxWidth: isPrinting ? 350 : "auto" }}>
        <Pie
          options={{
            layout: {
              padding: 16,
            },
          }}
          data={{
            labels: optionData.map((option) => option?.label),
            datasets: [
              {
                label: "% of responses",
                data: getOptionPercentages(),
                backgroundColor: [
                  "rgba(255, 99, 132, 0.2)",
                  "rgba(54, 162, 235, 0.2)",
                  "rgba(255, 206, 86, 0.2)",
                  "rgba(75, 192, 192, 0.2)",
                  "rgba(153, 102, 255, 0.2)",
                  "rgba(255, 159, 64, 0.2)",
                ],
                borderColor: [
                  "rgba(255, 99, 132, 1)",
                  "rgba(54, 162, 235, 1)",
                  "rgba(255, 206, 86, 1)",
                  "rgba(75, 192, 192, 1)",
                  "rgba(153, 102, 255, 1)",
                  "rgba(255, 159, 64, 1)",
                ],
                borderWidth: 1,
              },
            ],
          }}
        />{" "}
      </div>
    </div>
  )
}

export default QuestionAnalysis
