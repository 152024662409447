import styles from "./Ranking.module.css"
import Row from "rsuite/Row"
import Col from "rsuite/Col"
import {
  Button,
  Panel,
  Modal,
  Table,
  IconButton,
  Message,
  Stack,
  Whisper,
  Tooltip,
  Loader,
} from "rsuite"
import { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import SubmissionList from "./SubmissionList"
import ExpandOutlineIcon from "@rsuite/icons/ExpandOutline"
import CollaspedOutlineIcon from "@rsuite/icons/CollaspedOutline"
import { selectFormQuestions } from "../../features/selectQuestions/formQuestionsSlice"
import { selectConfiguration } from "../reportConfiguration/configurationSlice"
import ExitIcon from "@rsuite/icons/Exit"

const COLUMNS = [1, 1, 2, 11, 4, 2, 3]
const { Column, HeaderCell, Cell } = Table
const rowKey = "submissionId"

const ExpandCell = ({
  rowData,
  dataKey,
  expandedRowKeys,
  onChange,
  ...props
}) => (
  <Cell {...props} style={{ padding: 5 }}>
    <IconButton
      appearance="subtle"
      onClick={() => {
        onChange(rowData)
      }}
      icon={
        expandedRowKeys.some((key) => key === rowData[rowKey]) ? (
          <CollaspedOutlineIcon />
        ) : (
          <ExpandOutlineIcon />
        )
      }
    />
  </Cell>
)

const SubmissionRankings = ({
  rankings,
  previousRankings,
  orgApiKey,
  isLoading,
  UpdateReportButtons,
}) => {
  const config = useSelector(selectConfiguration)
  const [rankedSubmissions, setRankedSubmissions] = useState(
    rankings?.rankedSubmissions || [],
  )

  useEffect(() => {
    setRankedSubmissions(rankings?.rankedSubmissions)
  }, [rankings])

  const [modalIsOpen, setModalIsOpen] = useState(false)

  const [expandedRowKeys, setExpandedRowKeys] = useState([])
  const formFields = useSelector(selectFormQuestions)

  const handleExpanded = (rowData, dataKey) => {
    let open = false
    const nextExpandedRowKeys = []

    expandedRowKeys.forEach((key) => {
      if (key === rowData[rowKey]) {
        open = true
      } else {
        nextExpandedRowKeys.push(key)
      }
    })

    if (!open) {
      nextExpandedRowKeys.push(rowData[rowKey])
    }

    setExpandedRowKeys(nextExpandedRowKeys)
  }

  const renderRowExpanded = (rowData) => {
    let responses = rowData?.weightedFormResponses

    return (
      <Panel bordered>
        <Stack spacing={50}>
          <Message
            style={{ width: 230, textAlign: "center" }}
            showIcon
            type="success"
          >
            <h4>Score: {Math.round(rowData.score * 100) / 100}%</h4>
          </Message>
          <h4>{rowData.submitterName}</h4>
          <IconButton
            href={`https://${config?.organization?.domain}/submissions/${rowData?.submissionId}`}
            target="_blank"
            icon={<ExitIcon />}
            appearance="link"
          >
            View Submission
          </IconButton>
        </Stack>

        {responses.map((resp) => {
          let field = Object.values(formFields)?.filter(
            (field) => field?.fieldData?.formFieldId === resp.fieldId,
          )[0]

          if (!field) return null

          let answerOptionLabel

          if (!!field?.fieldData?.options) {
            answerOptionLabel = field?.fieldData?.options.filter(
              (option) => option.formOptionId === resp.value,
            )[0]?.label
          }

          return (
            <Panel>
              <h6>
                {field?.fieldData?.label} (Score:{" "}
                {Math.round(resp.score * 100) / 100}%)
              </h6>
              <br />
              {answerOptionLabel ? (
                <div>
                  <p>{answerOptionLabel}</p>
                </div>
              ) : (
                <div>
                  <p>{resp.value}</p>
                </div>
              )}
            </Panel>
          )
        })}
      </Panel>
    )
  }

  const getIndexDiff = (ranking) => {
    // Do we have a previous result to compare with?
    if (previousRankings?.rankedSubmissions?.length) {
      let prevIndex = previousRankings?.rankedSubmissions?.findIndex(
        (s) => s.submissionId === ranking.submissionId,
      )

      let currentIndex = rankedSubmissions?.findIndex(
        (s) => s.submissionId === ranking.submissionId,
      )

      if (prevIndex === -1) {
        return "New"
      }

      let diff = prevIndex - currentIndex

      if (diff > 0) {
        return `+${diff}`
      } else {
        return diff
      }
    }

    // No? Fine, this was our first time scoring anyway
    return "New"
  }

  const getButtonStyle = (ranking) => {
    let style = {
      width: "50px",
      backgroundColor: "#f5f5f5",
      color: "black",
    }
    let diff = getIndexDiff(ranking)

    if (diff > 0) {
      style.backgroundColor = "green"
      style.color = "white"
    } else if (diff < 0) {
      style.backgroundColor = "red"
      style.color = "white"
    }

    return style
  }

  const getScoreDiff = (ranking) => {
    // Do we have a previous result to compare with?
    if (previousRankings?.rankedSubmissions?.length) {
      let prevScore = previousRankings?.rankedSubmissions?.find(
        (x) => x.submissionId === ranking.submissionId,
      )?.score
      let currentScore = ranking?.score

      let diff = Math.round((currentScore - prevScore) * 100) / 100

      if (diff > 0) {
        return `(+${diff}%)`
      } else if (diff < 0) {
        return `(${diff}%)`
      } else {
        return ""
      }
    }

    // No? Fine, this was our first time scoring anyway
    return ""
  }

  const submissionListHeader = () => (
    <Row className={styles.gridHeader}>
      <Col className={styles.gridCol} xs={COLUMNS[0]}>
        {isLoading && <Loader center size="md" />}
      </Col>
      <Col className={styles.gridCol} xs={COLUMNS[1]}>
        Rank
      </Col>
      <Col className={styles.gridCol} xs={COLUMNS[2]}>
        Change
      </Col>
      <Col className={styles.gridCol} xs={COLUMNS[3]}>
        Submission Title
      </Col>
      <Col className={styles.gridCol} xs={COLUMNS[4]}>
        Submitter
      </Col>
      <Col className={styles.gridCol} xs={COLUMNS[5]}>
        Score
      </Col>
      <Col className={styles.gridCol} xs={COLUMNS[6]}>
        {
          <IconButton
            icon={<ExpandOutlineIcon />}
            onClick={() => {
              setModalIsOpen(true)
              setExpandedRowKeys([])
            }}
            placement="right"
            appearance="ghost"
          >
            Expand View
          </IconButton>
        }
      </Col>
    </Row>
  )

  const renderModal = () => (
    <Modal
      overflow={false}
      size="full"
      open={modalIsOpen}
      onClose={() => setModalIsOpen(false)}
    >
      <Modal.Header closeButton onClose={() => setModalIsOpen(false)} />

      <Modal.Body>
        <IconButton
          appearance="primary"
          onClick={() => {
            if (expandedRowKeys?.length) {
              setExpandedRowKeys([])
            } else {
              setExpandedRowKeys(
                Object.values(rankedSubmissions).map((obj) => obj.submissionId),
              )
            }
          }}
          style={{}}
          icon={
            expandedRowKeys?.length ? (
              <CollaspedOutlineIcon />
            ) : (
              <ExpandOutlineIcon />
            )
          }
        >
          {expandedRowKeys?.length ? <p>Close All</p> : <p>Expand All</p>}
        </IconButton>
        <Table
          wordWrap={"break-word"}
          loading={isLoading}
          autoHeight
          data={rankedSubmissions}
          rowKey={"submissionId"}
          expandedRowKeys={expandedRowKeys}
          renderRowExpanded={renderRowExpanded}
          shouldUpdateScroll={false}
          rowExpandedHeight={1200}
        >
          <Column flexGrow={1} verticalAlign="middle" width={70} align="center">
            <HeaderCell></HeaderCell>
            <ExpandCell
              dataKey="id"
              expandedRowKeys={expandedRowKeys}
              onChange={handleExpanded}
            />
          </Column>

          <Column flexGrow={1} verticalAlign="middle" width={70} align="center">
            <HeaderCell>
              <h6>Rank</h6>
            </HeaderCell>
            <Cell>
              {(rowData) =>
                rankedSubmissions.findIndex(
                  (sub) => rowData.submissionId === sub.submissionId,
                ) + 1
              }
            </Cell>
          </Column>

          <Column flexGrow={2} verticalAlign="middle">
            <HeaderCell>
              <h6>Change</h6>
            </HeaderCell>
            <Cell>
              {(rowData) => (
                <Whisper
                  placement="top"
                  controlId="control-id-hover"
                  trigger="hover"
                  speaker={
                    <Tooltip>
                      {getIndexDiff(rowData) === "New"
                        ? `This is a new ranking`
                        : `The rank of this submission changed by ${getIndexDiff(
                            rowData,
                          )} after the latest updates`}
                    </Tooltip>
                  }
                >
                  <Button appearance="primary" style={getButtonStyle(rowData)}>
                    {getIndexDiff(rowData)}
                  </Button>
                </Whisper>
              )}
            </Cell>
          </Column>

          <Column flexGrow={3} verticalAlign="middle">
            <HeaderCell>
              <h6>Submission Id</h6>
            </HeaderCell>
            <Cell dataKey="submissionId">
              {(rowData) => (
                <a
                  href={`https://${config?.organization?.domain}/submissions/${rowData?.submissionId}`}
                  target="_blank"
                >
                  {rowData?.submissionId}
                </a>
              )}
            </Cell>
          </Column>

          <Column flexGrow={10} verticalAlign="middle">
            <HeaderCell>
              <h6>Submission Title</h6>
            </HeaderCell>
            <Cell dataKey="submissionTitle" />
          </Column>

          <Column flexGrow={5} verticalAlign="middle">
            <HeaderCell>
              <h6>Submitter Name</h6>
            </HeaderCell>
            <Cell dataKey="submitterName">
              {(rowData) => <b>{rowData.submitterName}</b>}
            </Cell>
          </Column>

          <Column flexGrow={5} verticalAlign="middle">
            <HeaderCell>
              <h6>Score</h6>
            </HeaderCell>
            <Cell dataKey="score">
              {(rowData) =>
                `${Math.round(rowData.score * 100) / 100}% ${getScoreDiff(
                  rowData,
                )}`
              }
            </Cell>
          </Column>
        </Table>
      </Modal.Body>
      <Modal.Footer>
        <div style={{ paddingTop: "16px", justifyContent: "flex-end" }}>
          <Button
            size="lg"
            appearance="ghost"
            onClick={() => setModalIsOpen(false)}
          >
            Close
          </Button>
        </div>{" "}
      </Modal.Footer>
    </Modal>
  )

  return (
    <div>
      <Panel bordered>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <h3>Submission Rankings</h3>
          {UpdateReportButtons}
        </div>
        <br />
        <Panel bodyFill bordered>
          {submissionListHeader()}
          {renderModal()}
          <SubmissionList
            rankings={rankings}
            previousRankings={previousRankings}
            isLoading={isLoading}
            submissionCount={10}
            expandable={false}
          />
        </Panel>
      </Panel>
    </div>
  )
}

export default SubmissionRankings
