import Analysis from "../analysis/Analysis"
import SubmissionRankings from "../submissionRankings/SubmissionRankings"
import AssignQuestionWeights from "../assignQuestionWeights/AssignQuestionWeights"
import { useState } from "react"
import { Button, Loader, Modal, Form, Input, InputNumber, Panel } from "rsuite"
import {
  addMissionStatement,
  addNumberToRank,
  selectConfiguration,
} from "../reportConfiguration/configurationSlice"
import { useAppSelector, useAppDispatch } from "../../app/hooks"
import RankingSummary from "../submissionRankings/RankingSummary"

const PredicitonReport = ({
  rankings,
  previousRankings,
  handleSubmit,
  orgApiKey,
  isLoading,
}) => {
  const dispatch = useAppDispatch()
  const configuration = useAppSelector(selectConfiguration)

  const [weightingModalOpen, setWeightingModalOpen] = useState(false)
  const [configModalOpen, setConfigModalOpen] = useState(false)

  const [missionStatement, setMissionStatement] = useState(
    configuration?.missionStatement ?? "",
  )

  const handleClick = () => {
    handleSubmit()
    setWeightingModalOpen(false)
    setConfigModalOpen(false)
  }

  const handleMissionStatementChange = (value) => {
    setMissionStatement(value)
    dispatch(addMissionStatement(value))
  }

  const handleNumberToRankChange = (value) => {
    dispatch(addNumberToRank(value))
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "24px",
      }}
    >
      <div style={{ display: "flex", flexDirection: "column", gap: "24px" }}>
        <SubmissionRankings
          rankings={rankings}
          previousRankings={previousRankings}
          orgApiKey={orgApiKey}
          isLoading={isLoading}
          UpdateReportButtons={
            <div
              style={{
                display: "flex",
                gap: 8,
              }}
            >
              <Button
                disabled={isLoading}
                appearance="primary"
                onClick={() => setConfigModalOpen(true)}
              >
                Report Configuration
              </Button>
              <Button
                disabled={isLoading}
                appearance="primary"
                onClick={() => setWeightingModalOpen(true)}
              >
                Update Weights
              </Button>
            </div>
          }
        />
        <Panel bordered>
          <RankingSummary
            rankings={rankings}
            submissionCount={rankings?.rankedSubmissionCount}
            orgApiKey={orgApiKey}
            isLoadingRankings={isLoading}
          />
        </Panel>
        <Modal size="full" open={weightingModalOpen}>
          <Modal.Header
            closeButton
            onClose={() => setWeightingModalOpen(false)}
          />
          <Modal.Body>
            <div style={{ width: "calc(100% - 32px)" }}>
              <AssignQuestionWeights assignPreWeights={false} />
            </div>{" "}
          </Modal.Body>
          <Modal.Footer>
            <div style={{ paddingTop: "16px", justifyContent: "flex-end" }}>
              <Button
                size="lg"
                appearance="ghost"
                onClick={() => setWeightingModalOpen(false)}
              >
                Close
              </Button>
              <Button size="lg" appearance="primary" onClick={handleClick}>
                Submit
              </Button>
            </div>{" "}
          </Modal.Footer>
        </Modal>
        <Modal size="lg" open={configModalOpen}>
          <Modal.Header closeButton onClose={() => setConfigModalOpen(false)} />
          <Modal.Body>
            <div style={{ width: "calc(100% - 32px)" }}>
              <Panel bordered>
                <h3 style={{ paddingBottom: 16 }}>Report Configuration</h3>
                <Form.Group controlId="missionStatement">
                  <Form.ControlLabel>
                    <h5>Mission Statement</h5>
                    <p>
                      A mission statement is collected to help inform and
                      influence the AI scoring your submissions about what is
                      broadly important to your organization.
                    </p>
                    <br />
                  </Form.ControlLabel>
                  <Input
                    as="textarea"
                    rows={5}
                    placeholder="Mission Statement"
                    value={missionStatement}
                    onChange={handleMissionStatementChange}
                  />
                </Form.Group>
                <br />
                <Form.Group controlId="missionStatement">
                  <h5>
                    Show me the top{" "}
                    <InputNumber
                      onChange={handleNumberToRankChange}
                      value={configuration?.numberToRank}
                      min={10}
                      max={30}
                      style={{ width: "72px", display: "inline-flex" }}
                    ></InputNumber>{" "}
                    applicants.
                  </h5>
                </Form.Group>
              </Panel>
            </div>{" "}
          </Modal.Body>
          <Modal.Footer>
            <div style={{ paddingTop: "16px", justifyContent: "flex-end" }}>
              <Button
                size="lg"
                appearance="ghost"
                onClick={() => setConfigModalOpen(false)}
              >
                Close
              </Button>
              <Button size="lg" appearance="primary" onClick={handleClick}>
                Submit
              </Button>
            </div>{" "}
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  )
}

export default PredicitonReport
