import { arrayOf, object } from "prop-types"
import { formatScore, averageScore, topScores } from "../../utils/scoreUtils"
import styles from "./Ranking.module.css"
import {
  useGetAnalysisDescriptionMutation,
  useGetScoreDistributionQuery,
  useGetAnalysisProblemMutation,
} from "../../app/apis/crystalBall"
import { Loader, Nav, Message } from "rsuite"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { getStore } from "../../app/store"
import { selectConfiguration } from "../reportConfiguration/configurationSlice"
import { Line } from "react-chartjs-2"
import Analysis from "../analysis/Analysis"

export const ScoreDistribution = ({
  response,
  rankings,
  title = "Score Distribution",
  isLoading,
  includeTitle = true,
}) => {
  const { data: scoreDistribution, isLoading: isLoadingDistribution } = response

  return (
    <div>
      {includeTitle && <h3 style={{ paddingBottom: 16 }}>{title}</h3>}
      {isLoadingDistribution || isLoading ? (
        <Loader size="md" />
      ) : (
        <Line
          options={{
            scales: {
              x: {
                type: "linear",
                max: scoreDistribution?.scoreDistributions[0]?.score,
                min: scoreDistribution?.scoreDistributions[
                  scoreDistribution?.scoreDistributions.length - 1
                ].score,
                title: {
                  display: true,
                  text: "Score (%)",
                  font: {
                    size: 16,
                  },
                },
              },
              y: {
                title: {
                  display: true,
                  text: "# of Submissions",
                  font: {
                    size: 16,
                  },
                },
              },
            },
            plugins: {
              annotation: {
                common: {
                  drawTime: "afterDraw",
                },
                annotations: {
                  selectedSubmissionsBox: {
                    drawTime: "afterDraw",
                    type: "box",
                    backgroundColor: "rgba(159, 168, 218, 0.2)",
                    borderWidth: 0,
                    xMin: rankings?.rankedSubmissions[
                      rankings?.rankedSubmissions?.length - 1
                    ].score,
                    label: {
                      drawTime: "afterDraw",
                      display: true,
                      content: `Selected Submissions (Top ${rankings?.rankedSubmissions?.length})`,
                      position: {
                        x: "end",
                        y: "start",
                      },
                    },
                  },
                  selectedSubmissionsLine: {
                    drawTime: "afterDraw",
                    type: "line",
                    backgroundColor: "rgba(159, 168, 218, 0.2)",
                    borderWidth: 2,
                    borderDash: [6, 6],
                    scaleID: "x",
                    value:
                      rankings?.rankedSubmissions[
                        rankings?.rankedSubmissions?.length - 1
                      ]?.score,
                    label: {
                      drawTime: "afterDraw",
                      display: true,
                      content: `Cut line: ${
                        Math.round(
                          rankings?.rankedSubmissions[
                            rankings?.rankedSubmissions?.length - 1
                          ]?.score * 100,
                        ) / 100
                      }%`,
                      position: "center",
                    },
                  },
                },
              },
              legend: {
                display: false,
              },
            },
          }}
          data={{
            datasets: [
              {
                tension: 0.4,
                fill: true,
                data: Object.values(scoreDistribution?.scoreDistributions)
                  .reverse()
                  .map((d) => ({ y: d?.count, x: d?.score })),
                borderColor: "rgb(53, 162, 235)",
                backgroundColor: "rgba(53, 162, 235, 0.5)",
              },
            ],
          }}
        />
      )}
    </div>
  )
}

const RankingSummary = ({
  rankings,
  submissionCount,
  orgApiKey,
  isLoadingRankings,
}) => {
  const store = useSelector(getStore)
  const config = useSelector(selectConfiguration)

  const [
    callDescriptionAnalysisApi,
    { data: descriptionAnalysisData, isLoading: isLoadingDescriptionAnalysis },
  ] = useGetAnalysisDescriptionMutation({
    fixedCacheKey: "analysis-description",
  })
  const [
    callProblemAnalysisApi,
    { data: problemAnalysisData, isLoading: isLoadingProblemAnalysis },
  ] = useGetAnalysisProblemMutation({
    fixedCacheKey: "analysis-problem",
  })

  const response = useGetScoreDistributionQuery({
    orgApiKey,
    projectId: config?.projectId,
  })

  const getSelectedSubmissionsAnswersForField = (fieldLabel) => {
    let descriptionFormFieldId = Object.values(store?.formQuestions).filter(
      (q) => q.fieldData.label === fieldLabel,
    )[0].fieldData.formFieldId

    let answersArr = []

    rankings?.rankedSubmissions?.forEach((submission) => {
      return submission?.weightedFormResponses.forEach((resp) => {
        if (resp?.fieldId === descriptionFormFieldId) {
          answersArr.push(resp?.value)
        }
      })
    })

    return answersArr
  }

  const getFieldData = (fieldLabel, property = "scoringPrompt") => {
    let field = Object.values(store?.formQuestions).filter(
      (q) => q.fieldData.label === fieldLabel,
    )

    return field[0][property]
  }

  useEffect(() => {
    if (!isLoadingRankings) {
      callDescriptionAnalysisApi({
        orgNarrative: getFieldData(
          "Please describe your organization, including your mission and activities",
          "scoringPrompt",
        ),
        grantProgramPurpose: getFieldData(
          "What is the problem to be addressed by this grant award?",
          "scoringPrompt",
        ),
        applicationDescriptions: getSelectedSubmissionsAnswersForField(
          "Please describe your organization, including your mission and activities",
        ),
        orgApiKey,
        orgName: config?.organization?.name,
      })
    }
  }, [isLoadingRankings, rankings])

  useEffect(() => {
    if (!isLoadingDescriptionAnalysis && !!descriptionAnalysisData) {
      callProblemAnalysisApi({
        orgNarrative: getFieldData(
          "Please describe your organization, including your mission and activities",
          "scoringPrompt",
        ),
        grantProgramPurpose: getFieldData(
          "What is the problem to be addressed by this grant award?",
          "scoringPrompt",
        ),
        descriptionNarrative: descriptionAnalysisData?.answer,
        applicationProblems: getSelectedSubmissionsAnswersForField(
          "What is the problem to be addressed by this grant award?",
        ),
        orgApiKey,
        orgName: config?.organization?.name,
      })
    }
  }, [descriptionAnalysisData, isLoadingDescriptionAnalysis])

  const [activeTab, setActiveTab] = useState("scoreDistribution")

  const Tabs = ({ active, onSelect, ...props }) => (
    <Nav {...props} activeKey={active} onSelect={onSelect}>
      <Nav.Item eventKey="scoreDistribution">
        <h4>Score Distribution</h4>
      </Nav.Item>
      <Nav.Item eventKey="multipleChoiceAnalysis">
        <h4>Multiple Choice Analysis</h4>
      </Nav.Item>
    </Nav>
  )

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "16px",
      }}
    >
      <h3>Pre-Award Prediction Summary</h3>

      {!problemAnalysisData?.answer ||
      isLoadingDescriptionAnalysis ||
      isLoadingProblemAnalysis ||
      isLoadingRankings ? (
        <Loader size="md" content="Generating Summary" />
      ) : (
        <>
          <div
            style={{
              display: "grid",
              gap: "48px",
              gridTemplateColumns: isLoadingDescriptionAnalysis
                ? "100%"
                : "70% 30%",

              width: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "16px",
              }}
            >
              <div
                style={{
                  minHeight: 200,
                }}
              >
                <h4>Narrative</h4>
                <div style={{ fontSize: "1rem", margin: "10px 0" }}>
                  {descriptionAnalysisData?.answer}
                </div>
                <br />
              </div>
            </div>
            <Message type="success">
              <h4>Quick Statistics</h4>
              <div className={styles.summaryItem}>
                Total Submissions:
                <span className={styles.summaryValue}>
                  {response?.data.totalSubmissions}
                </span>
              </div>
              <div className={styles.summaryItem}>
                Average Score:
                <span className={styles.summaryValue}>
                  {`${Math.round(
                    response?.data.averageScoreTotalSubmissions,
                  )}%`}
                </span>
              </div>
              <div className={styles.summaryItem}>
                Selected Submissions:
                <span className={styles.summaryValue}>
                  {response?.data.topSubmissions}
                </span>
              </div>
              <div className={styles.summaryItem}>
                Average Selected Submission Score:
                <span className={styles.summaryValue}>
                  {`${Math.round(response?.data.averageScoreTopSubmissions)}%`}{" "}
                </span>
              </div>
            </Message>
          </div>
          <div>
            <h4>Problem Statement</h4>
            <div style={{ fontSize: "1rem", margin: "10px 0" }}>
              {problemAnalysisData?.answer}
            </div>
          </div>
        </>
      )}

      <Tabs appearance="subtle" active={activeTab} onSelect={setActiveTab} />
      <div style={{ padding: 32 }}>
        {activeTab === "scoreDistribution" && (
          <ScoreDistribution
            rankings={rankings}
            response={response}
            isLoading={isLoadingRankings}
            includeTitle={false}
          />
        )}
        {activeTab === "multipleChoiceAnalysis" && (
          <Analysis
            rankings={rankings}
            noBorder
            isLoadingRankings={isLoadingRankings}
            includeTitle={false}
          />
        )}{" "}
      </div>
    </div>
  )
}

RankingSummary.propTypes = {
  rankings: arrayOf(object),
}

export default RankingSummary
