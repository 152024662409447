import QuestionAnalysis from "./QuestionAnalysis"
import { useSelector } from "react-redux"
import { Loader, Panel } from "rsuite"
import { selectFormQuestions } from "../selectQuestions/formQuestionsSlice"

const Analysis = ({
  title = "Multiple Choice Analysis",
  rankings,
  noBorder = false,
  pieRadius = 125,
  isLoadingRankings,
  includeTitle = true,
  isPrinting = false,
}) => {
  const responses = rankings?.rankedSubmissions?.reduce((acc, ranking) => {
    return [...ranking.weightedFormResponses, ...acc]
  }, [])

  const formFields = useSelector(selectFormQuestions)

  const fields = Object.keys(formFields).map((fieldId) => {
    const field = formFields[fieldId]
    const options = field.options

    // count the options
    const optionCounts = Object.keys(options).map((optionId) => {
      const weight = options[optionId]
      const count = responses?.filter((response) => {
        return response.value === optionId
      }).length

      return {
        optionId,
        weight,
        count,
      }
    })

    return {
      ...field,
      optionCounts,
    }
  })

  const Wrapper = noBorder
    ? (children) => <div>{children}</div>
    : (children) => <Panel bordered>{children}</Panel>

  return Wrapper(
    <>
      {includeTitle && <h3 style={{ paddingBottom: 16 }}>{title}</h3>}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "row",
          gap: "32px",
          flexWrap: "wrap",
        }}
      >
        {isLoadingRankings ? (
          <Loader size="lg" style={{ width: "100%", textAlign: "center" }} />
        ) : (
          rankings &&
          fields.map((question) => (
            <QuestionAnalysis
              pieRadius={pieRadius}
              question={question}
              isPrinting={isPrinting}
            />
          ))
        )}
      </div>
    </>,
  )
}

export default Analysis
