import { useState, useEffect } from "react"
import {
  useGetProjectsQuery,
  useGetFormsQuery,
  useGetOrganizationQuery,
} from "../../app/apis/servicesV3"
import {
  Form,
  Input,
  SelectPicker,
  Notification,
  Panel,
  InputNumber,
} from "rsuite"
import {
  addInitialFormId,
  addProjectId,
  addMissionStatement,
  selectConfiguration,
  addOrganizationInfo,
  addNumberToRank,
} from "./configurationSlice"
import { useAppSelector, useAppDispatch } from "../../app/hooks"
import "./Configuration.css"
import SelectQuestions from "../selectQuestions/SelectQuestions"

const Configuration = () => {
  const configuration = useAppSelector(selectConfiguration)
  const orgApiKey = configuration.orgApiKey

  const [projects, setProjects] = useState([])
  const [projectId, setProjectId] = useState(configuration?.projectId ?? "")
  const [selectedProject, setSelectedProject] = useState()
  const [initialFormId, setInitialFormId] = useState(
    configuration?.intialFormId ?? "",
  )
  const [missionStatement, setMissionStatement] = useState(
    configuration?.missionStatement ?? "",
  )
  const [form, setForm] = useState()
  const [page, setPage] = useState(1)
  const dispatch = useAppDispatch()

  const formResult = useGetFormsQuery(
    {
      orgApiKey: orgApiKey,
      formId: initialFormId,
    },
    { skip: !selectedProject },
  )

  const projectResult = useGetProjectsQuery(
    {
      orgApiKey: orgApiKey,
      page: page,
    },
    { skip: !orgApiKey },
  )

  const organizationResult = useGetOrganizationQuery(
    {
      orgApiKey: orgApiKey,
    },
    { skip: !orgApiKey },
  )

  const projectSelectData = projects
    ? projects.map((project) => ({
        label: project?.name ?? "",
        value: project.projectId,
      }))
    : []

  useEffect(() => {
    if (projectResult.currentData?.items.length) {
      setProjects([...projects, projectResult.currentData?.items].flat())
      setPage(page + 1)
    }

    const demoProject = projects.find(
      (project) => project?.projectId === projectId,
    )

    if (demoProject) {
      setProjectId(demoProject.projectId)
      addProjectId(demoProject.projectId)
      setSelectedProject(demoProject)
    }
  }, [projectResult])

  useEffect(() => {
    setForm(formResult.data)
  }, [formResult])

  useEffect(() => {
    setInitialFormId(selectedProject?.initialFormId)
    dispatch(addInitialFormId(initialFormId))
  }, [selectedProject, initialFormId, dispatch])

  useEffect(() => {
    dispatch(addOrganizationInfo(organizationResult.data))
  }, [organizationResult, dispatch])

  const handleSelectProject = (value) => {
    setProjectId(value)
    setSelectedProject(
      projects?.filter((project) => project?.projectId === value)[0],
    )
    dispatch(addProjectId(value))
  }

  const handleMissionStatementChange = (value) => {
    setMissionStatement(value)
    dispatch(addMissionStatement(value))
  }

  const handleNumberToRankChange = (value) => {
    dispatch(addNumberToRank(value))
  }

  return (
    <div className="configuration">
      <Panel bordered>
        <div className="headerContainer">
          <h3>Find Project</h3>
          <p>
            Please choose the project of interest you'd like to receive award
            recommendations for.
          </p>
        </div>

        <Form fluid>
          <Form.Group controlId="projectId">
            <Form.ControlLabel>
              <h5>Project</h5>
            </Form.ControlLabel>
            <SelectPicker
              name="projectId"
              data={projectSelectData}
              onChange={handleSelectProject}
              value={projectId}
              style={{ width: "100%" }}
            />
            {form && (
              <Notification
                type="success"
                header={`Successfully selected form`}
                style={{ marginTop: "8px", width: "100%" }}
              >
                "{form.name}"
              </Notification>
            )}
          </Form.Group>

          <div className="headerContainer">
            <h3>Report Configuration</h3>
          </div>
          <Form.Group controlId="missionStatement">
            <Form.ControlLabel>
              <h5>Mission Statement</h5>
              <p>
                A mission statement is collected to help inform and influence
                the AI scoring your submissions about what is broadly important
                to your organization.
              </p>
            </Form.ControlLabel>
            <Input
              as="textarea"
              rows={8}
              placeholder="Mission Statement"
              value={missionStatement}
              onChange={handleMissionStatementChange}
            />
          </Form.Group>
          <Form.Group controlId="missionStatement">
            <h5>
              Show me the top{" "}
              <InputNumber
                onChange={handleNumberToRankChange}
                value={configuration?.numberToRank}
                min={10}
                max={30}
                style={{ width: "72px", display: "inline-flex" }}
              ></InputNumber>{" "}
              applicants.
            </h5>
          </Form.Group>
        </Form>
      </Panel>
      <Panel bordered>
        <SelectQuestions />
      </Panel>
    </div>
  )
}

export default Configuration
