export const SUPPORTED_FIELD_TYPES = {
  short_answer: "Short answer",
  long_answer: "Long answer",
  dropdown_list: "Dropdown list",
  single_checkbox: "Single checkbox",
  single_response: "Single response",
}

export const SCORABLE_FIELD_TYPES = [
  "dropdown_list",
  "single_checkbox",
  "single_response",
]
