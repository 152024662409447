import { Loader } from "rsuite"
import FormQuestionSelector from "./FormQuestionSelector"
import { useGetFormsQuery } from "../../app/apis/servicesV3"
import {
  addField,
  removeField,
  selectFormQuestions,
  selectFormQuestionsCount,
} from "./formQuestionsSlice"
import { useSelector, useDispatch } from "react-redux"
import style from "./index.module.css"
import { selectConfiguration } from "../reportConfiguration/configurationSlice"
import { SUPPORTED_FIELD_TYPES } from "../../utils/constants"
import { useEffect } from "react"

const SelectQuestions = () => {
  const dispatch = useDispatch()
  const formQuestions = useSelector(selectFormQuestions)
  const selectedCount = useSelector(selectFormQuestionsCount)

  const config = useSelector(selectConfiguration)

  const { data, isLoading } = useGetFormsQuery({
    orgApiKey: config.orgApiKey,
    formId: config.initialFormId,
  })

  useEffect(() => {
    if (!data?.fields || Object.keys(formQuestions).length !== 0) {
      return
    }

    const selectedQuestionLabels = [
      "What is your gender?",
      "What is your ethnicity/race?",
      "What is your annual household income?",
      "Please describe your organization, including your mission and activities",
      "What is the problem to be addressed by this grant award?",
    ]

    const defaultFields = data.fields.filter((question) =>
      selectedQuestionLabels.includes(question.label),
    )

    defaultFields.forEach((field) =>
      formQuestions[field.formFieldId] === undefined
        ? dispatch(addField(field))
        : null,
    )
  }, [data])

  const handleCheckboxClick = (fieldData) => {
    if (formQuestions[fieldData.formFieldId] === undefined) {
      dispatch(addField(fieldData))
    } else {
      dispatch(removeField(fieldData))
    }
  }

  if (isLoading) {
    return <Loader />
  }

  return (
    <div className={style.root}>
      <span className="headerContainer">
        <h3>Select Questions</h3>
        <p>
          Please select 5-10 questions on the form that you'd like to use for
          automatic submission scoring. The questions you select should be the
          most influential for decision making in the awarding process for your
          grant.
        </p>
      </span>

      {data?.fields?.map((fieldData) =>
        Object.keys(SUPPORTED_FIELD_TYPES).includes(fieldData.fieldType) ? (
          <FormQuestionSelector
            key={fieldData.formFieldId}
            fieldData={fieldData}
            isSelected={Object.keys(formQuestions).includes(
              fieldData.formFieldId,
            )}
            onSelect={() => handleCheckboxClick(fieldData)}
          />
        ) : null,
      )}

      <p>{selectedCount} question(s) selected</p>
    </div>
  )
}

export default SelectQuestions
