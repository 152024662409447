import { useEffect } from "react"
import {
  useGetScoreDistributionQuery,
  useGetAnalysisDescriptionMutation,
  useGetAnalysisProblemMutation,
  useGetAnalysisImpactMutation,
  useGetAnalysisMetricsMutation,
} from "../../app/apis/crystalBall"
import { Loader, Panel, Button } from "rsuite"
import { useSelector } from "react-redux"
import { selectConfiguration } from "../reportConfiguration/configurationSlice"
import Analysis from "../analysis/Analysis"
import { ScoreDistribution } from "../submissionRankings/RankingSummary"
import { useRef } from "react"
import { useReactToPrint } from "react-to-print"
import { getStore } from "../../app/store"
import styles from "./postAward.module.css"

const PrintContainer = ({ children }) => {
  return (
    <div style={{ margin: "0", padding: "0" }}>
      <div>{children}</div>
      <div className="page-break" />
    </div>
  )
}

const PostAwardAnalysis = ({ rankings, orgApiKey }) => {
  const store = useSelector(getStore)
  const printRef = useRef()
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    pageStyle:
      "h3 { font-size: 24px !important; } h4 { font-size: 16px !important; }",
  })

  const getSelectedSubmissionsAnswersForField = (fieldId) => {
    let answersArr = []

    rankings?.rankedSubmissions?.forEach((submission) => {
      return submission?.weightedFormResponses.forEach((resp) => {
        if (resp?.fieldId === fieldId) {
          answersArr.push(resp?.value)
        }
      })
    })

    return answersArr
  }

  const getFieldData = (fieldLabel, property = "scoringPrompt") => {
    let field = Object.values(store?.formQuestions).filter(
      (q) => q.fieldData.label === fieldLabel,
    )

    return field[0][property]
  }

  const config = useSelector(selectConfiguration)

  const [
    callDescriptionAnalysisApi,
    { data: descriptionAnalysisData, isLoading: isLoadingDescriptionAnalysis },
  ] = useGetAnalysisDescriptionMutation({
    fixedCacheKey: "analysis-description",
  })
  const [
    callProblemAnalysisApi,
    { data: problemAnalysisData, isLoading: isLoadingProblemAnalysis },
  ] = useGetAnalysisProblemMutation({
    fixedCacheKey: "analysis-problem",
  })
  const [
    callImpactAnalysisApi,
    { data: impactAnalysisData, isLoading: isLoadingImpactAnalysis },
  ] = useGetAnalysisImpactMutation()
  const [
    callMetricsAnalysisApi,
    { data: metricsAnalysisData, isLoading: isLoadingMetricsAnalysis },
  ] = useGetAnalysisMetricsMutation()
  const response = useGetScoreDistributionQuery({
    orgApiKey,
    projectId: config?.projectId,
  })

  useEffect(() => {
    if (!isLoadingProblemAnalysis && !!problemAnalysisData) {
      callImpactAnalysisApi({
        orgNarrative: getFieldData(
          "Please describe your organization, including your mission and activities",
          "scoringPrompt",
        ),
        grantProgramPurpose: getFieldData(
          "What is the problem to be addressed by this grant award?",
          "scoringPrompt",
        ),
        descriptionNarrative: descriptionAnalysisData?.answer,
        problemNarrative: problemAnalysisData?.answer,
        applicationImpacts: getSelectedSubmissionsAnswersForField(
          "99660a76-41d2-4568-8095-2b967941b629",
        ),
        orgApiKey,
        orgName: config?.organization?.name,
      })
    }
  }, [problemAnalysisData, isLoadingProblemAnalysis])

  useEffect(() => {
    if (!isLoadingImpactAnalysis && !!impactAnalysisData) {
      callMetricsAnalysisApi({
        orgNarrative: getFieldData(
          "Please describe your organization, including your mission and activities",
          "scoringPrompt",
        ),
        grantProgramPurpose: getFieldData(
          "What is the problem to be addressed by this grant award?",
          "scoringPrompt",
        ),
        descriptionNarrative: descriptionAnalysisData?.answer,
        problemNarrative: problemAnalysisData?.answer,
        impactNarrative: impactAnalysisData?.answer,
        applicationMetrics: getSelectedSubmissionsAnswersForField(
          "a6923920-69e1-4d52-9f23-53dbf919d5fe",
        ),
        orgApiKey,
        orgName: config?.organization?.name,
      })
    }
  }, [impactAnalysisData, isLoadingImpactAnalysis])

  return (
    <>
      <Panel bordered>
        <div
          style={{
            padding: 16,
            display: "flex",
            flexDirection: "column",
            gap: 32,
            fontSize: "1rem",
          }}
        >
          <div style={{ textAlign: "center" }}>
            <h2>Impact Report</h2>
          </div>
          <div style={{ display: "flex", gap: 32, flexDirection: "row" }}>
            <div>
              <img
                alt="organizationLogo"
                width={60}
                src="./assets/submittableLogo.png"
              />
            </div>
            <div style={{ display: "flex", flexDirection: "column", gap: 16 }}>
              <h3>{config.organization.name}'s Mission</h3>
              <p>{config.missionStatement}</p>
            </div>
          </div>

          <div style={{ display: "flex", flexDirection: "column", gap: 16 }}>
            <h3>Impact Statement</h3>
            {isLoadingImpactAnalysis ||
            isLoadingDescriptionAnalysis ||
            isLoadingProblemAnalysis ? (
              <Loader size="md" content="Generating impact statement" />
            ) : (
              <p>{impactAnalysisData?.answer}</p>
            )}
          </div>

          <div style={{ display: "flex", flexDirection: "column", gap: 16 }}>
            <h3>Metrics Statement</h3>
            {isLoadingMetricsAnalysis ||
            isLoadingDescriptionAnalysis ||
            isLoadingProblemAnalysis ||
            isLoadingImpactAnalysis ? (
              <Loader size="md" content="Generating metrics statement" />
            ) : (
              <p>{metricsAnalysisData?.answer}</p>
            )}
          </div>
          <Analysis title="Who We Served" rankings={rankings} noBorder />
          <ScoreDistribution
            title="How Fairly Did We Distribute?"
            rankings={rankings}
            response={response}
          />
          <div style={{ display: "flex", gap: 16, justifyContent: "center" }}>
            <Button
              appearance="primary"
              size="lg"
              onClick={() => handlePrint()}
            >
              Print to PDF
            </Button>
          </div>
          <div
            style={{
              display: "flex",
              gap: 16,
              justifyContent: "center",
              fontWeight: 500,
            }}
          >
            <img width={600} src="./assets/report_footer.png" alt="footer" />
          </div>
        </div>
      </Panel>

      {/* for printing to PDF, don't render in browser */}
      <div style={{ height: 0, overflow: "hidden" }}>
        <div bordered ref={printRef} style={{ width: 900 }}>
          <div
            style={{
              padding: 32,
              display: "flex",
              flexDirection: "column",
              gap: 32,
            }}
          >
            <div style={{ textAlign: "center" }}>
              <h2>Impact Report</h2>
            </div>
            <div style={{ display: "flex", gap: 32, flexDirection: "row" }}>
              <div>
                <img
                  alt="organizationLogo"
                  width={60}
                  src="./assets/submittableLogo.png"
                />
              </div>
              <div
                style={{ display: "flex", flexDirection: "column", gap: 16 }}
              >
                <h3>{config.organization.name}'s Mission</h3>
                <p>{config.missionStatement}</p>
              </div>
            </div>

            <PrintContainer>
              <div
                style={{ display: "flex", flexDirection: "column", gap: 16 }}
              >
                <h3>Impact Statement</h3>
                {isLoadingImpactAnalysis ||
                isLoadingDescriptionAnalysis ||
                isLoadingProblemAnalysis ? (
                  <Loader size="lg" style={{ textAlign: "center" }} />
                ) : (
                  <p>{impactAnalysisData?.answer}</p>
                )}
              </div>
            </PrintContainer>

            <div style={{ display: "flex", flexDirection: "column", gap: 16 }}>
              <h3>Metrics Statement</h3>
              {isLoadingMetricsAnalysis ||
              isLoadingDescriptionAnalysis ||
              isLoadingProblemAnalysis ||
              isLoadingImpactAnalysis ? (
                <Loader size="lg" style={{ textAlign: "center" }} />
              ) : (
                <p>{metricsAnalysisData?.answer}</p>
              )}
            </div>

            <PrintContainer>
              <Analysis
                title="Who We Served"
                rankings={rankings}
                noBorder
                pieRadius={100}
                isPrinting
              />{" "}
            </PrintContainer>

            <ScoreDistribution
              title="How Fairly Did We Distribute?"
              rankings={rankings}
              response={response}
            />

            <img
              alt="reportFooter"
              width={500}
              src="./assets/report_footer.png"
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default PostAwardAnalysis
